<style >
	.el-radio__label,
	.el-checkbox__label {
		color: rgba(0, 0, 0, 0.85);
		font-size: 14px;
	}
	.el-empty__description {
		text-align: center;
	}

	.el-link--inner {
		width: 100%;
		font-size: 13px;
		padding: 0 20px;
	}
	.el-checkbox__label,
	.el-radio__label {
		white-space: normal;
	}

	.el-checkbox__input,
	.el-radio__input {
		vertical-align: top;
	}
	h3 {
		font-weight: 400;
	}

	.el-progress-bar__outer {
		background: #ffffff55;
	}
	.el-message-box {
		width: 80%;
	}
</style>
<template>
	<div style="background: linear-gradient(150deg, #2c6dd2 50%, #5eb361);min-height:100%;padding:16px">
		<h1 style="color:#fff;text-align:center">密评知识答题系统
		</h1>
		<h3 style="color:#fff;line-height:44px">试卷编号：{{uuid}} <span style="margin-left:30px">考生姓名：{{name}}</span>
		</h3>
		<!-- <h6 style="text-align:right;color:#ffffffee"> 剩余时间：{{timer}}s</h6> -->
		<!-- <el-progress :percentage="timer/90*100" :stroke-width="8" :show-text="false" status="warning" style="margin-bottom:20px" /> -->
		<h2 style="color:#fff;line-height:44px">一、单选题</h2>
		<template v-for="(item,i) in s1">
			<el-card :key="'c-'+(i+1)" shadow="always" style="margin-bottom:20px">
				<h3 :key="'h3-'+(i+1)">{{i+1}}. {{item.title}}</h3>
				<h4 :key="'h4-'+(i+1)" style="margin:20px 0 0 0">
					<el-radio-group v-model="ans[i]" @change="((e)=>{submit(e,item.exam_id)})">
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="A">A. {{item.opt1}}</el-radio><br />
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="B">B. {{item.opt2}}</el-radio><br />
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="C">C. {{item.opt3}}</el-radio><br />
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="D" v-if="item.opt4!=''">D. {{item.opt4}}</el-radio><br />
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="E" v-if="item.opt5!=''">E. {{item.opt5}}</el-radio><br />
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="F" v-if="item.opt6!=''">F. {{item.opt6}}</el-radio><br />
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="G" v-if="item.opt7!=''">G. {{item.opt7}}</el-radio><br />
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="H" v-if="item.opt8!=''">H. {{item.opt8}}</el-radio><br />
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="I" v-if="item.opt9!=''">I. {{item.opt9}}</el-radio><br />
					</el-radio-group>
				</h4>
			</el-card>
		</template>
		<h3 style="color:#fff;line-height:44px;margin-top:20px">二、多选题</h3>
		<template v-for="(item,i) in s2" >
			<el-card :key="'c-'+(i+1)" shadow="always" style="margin-bottom:20px">
				<h3 :key="'h3-'+(i+1)">{{i+1}}. {{item.title}}</h3>
				<h4 :key="'h4-'+(i+1)" style="margin:20px 0 0 0">
					<!-- <el-checkbox-group v-model="ans[i+30]" @change="((e)=>{submit(e,item.exam_id)})"> -->
						<!-- <el-checkbox-group v-model="ans[i+30]" @change="submits(e)"> -->
						<el-checkbox-group v-model="ans[i+60]" >
						<el-checkbox style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="A">A. {{item.opt1}}</el-checkbox><br />
						<el-checkbox style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="B">B. {{item.opt2}}</el-checkbox><br />
						<el-checkbox style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="C">C. {{item.opt3}}</el-checkbox><br />
						<el-checkbox style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="D">D. {{item.opt4}}</el-checkbox><br />
						<el-checkbox style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="E" v-if="item.opt5!=''">E. {{item.opt5}}</el-checkbox><br />
						<el-checkbox style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="F" v-if="item.opt6!=''">F. {{item.opt6}}</el-checkbox><br />
						<el-checkbox style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="G" v-if="item.opt7!=''">G. {{item.opt7}}</el-checkbox><br />
						<el-checkbox style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="H" v-if="item.opt8!=''">H. {{item.opt8}}</el-checkbox><br />
						<el-checkbox style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="I" v-if="item.opt9!=''">I. {{item.opt9}}</el-checkbox><br />
					</el-checkbox-group>
				</h4>
				  <el-button type="primary" round size="mini" @click="submits(i,2,item.exam_id)">提交</el-button>
			</el-card>
			
		</template>
		<h3 style="color:#fff;line-height:44px;margin-top:20px">三、判断题</h3>
		<template v-for="(item,i) in s3">
			<el-card :key="'c-'+(i+1)" shadow="always" style="margin-bottom:20px">
				<h3 :key="'h3-'+(i+1)">{{i+1}}. {{item.title}}</h3>
				<h4 :key="'h4-'+(i+1)" style="margin:20px 0 0 0">
					<el-radio-group v-model="ans[i+70]" @change="((e)=>{submit(e,item.exam_id)})">
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="1">
							<i class="el-icon-check" style="color:green;font-weight:700"></i> 正确
						</el-radio><br />
						<el-radio style="margin-bottom:12px;background:#f5f5f5;padding:10px 18px;border-radius:50px" label="0">
							<i class="el-icon-close" style="color:red;font-weight:700"></i> 错误
						</el-radio>
					</el-radio-group>
				</h4>
			</el-card>
		</template>
		<h4 style="text-align:center;margin-bottom:20px"><el-button type="warning" round @click="re_exam">重新考试</el-button><el-button type="warning" round plain style="margin-left:15px" @click="logout">退出登录</el-button></h4>
	</div>
</template>
<script>
	import { MessageBox } from "element-ui";
	import request from "../../plugins/axios.js";
	export default {
		mounted() {
			document.title = "密评模考";
			request({
				url: "/study/re_exam_index/" + this.$route.params.id,
				data: {},
			}).then((ret) => {
				if (ret.code == 1) {
					this.uuid = ret.data.uuid;
					this.ans = ret.data.ans;
					this.s1 = ret.data.s1;
					this.s2 = ret.data.s2;
					this.s3 = ret.data.s3;
					// this.timer = 60 * 60;
					// this.t = setInterval(() => {
					// 	this.timer = this.timer - 1;
					// 	if (this.timer <= 0) {
					// 		clearInterval(this.t);
					// 	}
					// }, 1000);
				} else {
					if (ret.msg == "请重新登录") {
						this.$router.push("/login");
					} else {
						this.$router.push("/paper");
					}
				}
			});
		},
		methods: {
			re_exam() {
				MessageBox.confirm("重新考试将删除之前的答题记录, 是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "warning",
				})
					.then(() => {
						request({
							url: "/study/re_exam_clear/" + this.uuid,
							data: {},
						}).then((ret) => {
							if (ret.code == 1) {
								window.location.href = "/exam/" + this.uuid;
								// this.$router.push("/exam/" + this.uuid);
							}
						});
					})
					.catch(() => {});
			},
			submit(e, exam_id) {


				request({
					url: "/study/re_exam_submit/" + this.$route.params.id,
					data: {
						exam_id,
						ans: e,
					},
				}).then((ret) => {
					if (ret.code == 1) {
					} else {
						this.$router.push("/paper");
					}
				});
			},
				submits(i,type,exam_id) {

				let that=this
				console.log(that.ans[i+60])
				console.log(i)
				if(type==2){
					let answer=that.ans[i+60]
						request({
									url: "/study/re_exam_submit/" + this.$route.params.id,
									data: {
										exam_id,
										ans: answer,
									},
								}).then((ret) => {
									if (ret.code == 1) {
									} else {
										this.$router.push("/paper");
									}
								});
				}
		
			
			},
			logout() {
				window.localStorage.removeItem("token");
				window.localStorage.removeItem("name");
				this.$router.push("/paper");
			},
		},
		data() {
			return {
				name: window.localStorage.getItem("name"),
				uuid: "",
				s1: [],
				s2: [],
				s3: [],
				ans: [],
				answers:[]
				// timer: 60 * 60,
			};
		},
	};
</script>